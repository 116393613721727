import firebase from 'firebase/app';
import 'firebase/firestore';
require('firebase/auth');
import UserService from '@/api/UserService';

const firebaseConfig = {
  apiKey: 'AIzaSyAIqMtVXnRt9ido3LRTtn5MgweqHfpdXdU',
  authDomain: 'megacampus-fdf3f.firebaseapp.com',
  projectId: 'megacampus-fdf3f',
  storageBucket: 'megacampus-fdf3f.appspot.com',
  messagingSenderId: '537902505186',
  appId: '1:537902505186:web:65f7eaea73f27f4b1e12bb',
  measurementId: 'G-MP7YXNRSN1',
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

function getToken() {
  return UserService.getUserFirebaseToken();
}

getToken().then(function (result) {
  firebase
    .auth()
    .signInWithCustomToken(result.data.data)
    .then((userCredential) => {
      var user = userCredential.user;
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
    });
});
