<template>
  <div>
    <div class="app-modal modal-settings">
      <div class="modal-settings__top-block">
        <div class="modal-settings__title-block">
          <p>{{ $t('chat_settings') }}</p>
        </div>
        <button class="app-modal__close-btn" @click="closeModal">
          <SvgClose />
        </button>
      </div>

      <form @submit.prevent="updateChatSettings" class="form">
        <div class="form-body">
          <div>
            <p class="form-body__title">{{ $t('chat_visibility') }}</p>
            <div v-for="(option, idx) in visibilityOptions" :key="`visibility${idx}`">
              <input
                :id="`visibility${idx}`"
                type="radio"
                v-model="chatVisibility"
                name="chatVisibility"
                :value="option.value"
              />
              <label :for="`visibility${idx}`">{{ $t(option.label) }}</label>
            </div>
          </div>

          <div>
            <p class="form-body__title">{{ $t('chat_access') }}</p>
            <div v-for="(option, idx) in accessOptions" :key="`access${idx}`">
              <input
                :id="`access${idx}`"
                type="radio"
                v-model="chatAccess"
                name="chatAccess"
                :value="option.value"
              />
              <label :for="`access${idx}`">{{ $t(option.label) }}</label>
            </div>
          </div>
        </div>

        <div class="modal-settings__buttons">
          <button class="btn modal-settings__cancel-btn" @click="closeModal">
            {{ $t('cancel') }}
          </button>

          <button class="btn--blue modal-settings__submit-btn" type="submit">
            {{ $t('save') }}
          </button>
        </div>
      </form>
    </div>
    <div class="modal-overlay"></div>
  </div>
</template>

<script>
import ChatsService from '@/api/ChatsService';
import { chatVisibilityConfig, chatAccessConfig } from './config';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visibilityOptions: chatVisibilityConfig,
      accessOptions: chatAccessConfig,
      chatVisibility: this.item?.settings?.visible,
      chatAccess: this.item?.settings?.sendAccess,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async updateChatSettings() {
      const res = await ChatsService.updateChatSettings(this.item?.id, {
        visible: this.chatVisibility,
        sendAccess: this.chatAccess,
      });

      this.$emit('close');
      this.$emit('updated');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-settings {
  max-width: 560px;

  &__top-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 32px;
    border-bottom: 1px solid #dde1e6;

    p {
      font-weight: 500;
      font-size: var(--text-lg);
    }
  }
  &__title-block {
    display: flex;
    align-items: center;
    font-weight: 500;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
  }

  &__cancel-btn {
    color: #593bd2;
    font-weight: 500;
    margin-right: 10px;
  }

  &__submit-btn {
    width: max-content;
    display: block;
    padding: 11px 32px;
  }
}

.form {
  font-weight: 500;
  font-size: var(--text-sm);
  label {
    margin-left: 4px;
    color: #7c818b;
    cursor: pointer;
  }
}

.form-body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;

  &__title {
    color: #3c3d41;
    margin-bottom: 12px;
    font-weight: 600;
  }
}
</style>
