<template>
  <div>
    <div class="chat">
      <div class="chat__header-wrapper">
        <div class="container">
          <div class="chat__header">
            <a :href="previousPageUrl" class="btn-back">
              <SvgArrowLeft />
            </a>
            <a v-if="showAvatar" :href="interlocutorUrl">
              <Avatar class="chat__avatar" :initials="initials" :avatar="avatarImg" />
            </a>
            <div class="chat__name-wrapper">
              <a :href="interlocutorUrl" class="">
                <span class="chat__name">{{ chatName }}</span>
              </a>
              <p v-if="!showAvatar" class="chat__members-count">
                {{ chatMembersCount }} <span>{{ $t('chat_participants') }}</span>
              </p>
            </div>
            <div class="chat__dots-wrapper">
              <MenuButton v-if="showSettingsButton" @click="isSettingsModalVisible = true" />
            </div>
          </div>
        </div>
      </div>

      <div class="chat__body-wrapper">
        <div class="container">
          <div class="chat__body">
            <Observer @intersect="loadMore" />
            <Message
              v-for="(message, idx) in messages"
              :key="message.id"
              :message="message"
              :prevMessage="messages[idx - 1]"
              :isMessageMine="message.senderId === currentUser.id"
              :chatMembers="chatMembers"
            />
            <div class="dummy" ref="dummy"></div>
          </div>
        </div>
      </div>

      <div class="chat__footer-wrapper">
        <div class="container">
          <form v-if="showChatInput" action="" @submit.prevent="sendMessage" class="chat__footer">
            <div class="chat__footer-input-box">
              <SvgPencil v-if="!isMobile" />
              <label v-else class="btn--text">
                <SvgPaperclip />
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .bmp, .heic, .ico"
                  @change="uploadFile"
                />
              </label>
              <input type="text" v-model="newMessage" :placeholder="$t('write_message')" />
            </div>
            <div class="chat__footer-buttons">
              <label v-if="!isMobile" class="btn--text">
                <SvgPaperclip />
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .bmp, .heic, .ico"
                  @change="uploadFile"
                />
              </label>
              <div class="relative">
                <svg-smiley @click.native="invokePicker" class="btn--text mx-3" />
                <VEmojiPicker
                  v-if="isEmojipickerActive"
                  @select="selectEmoji"
                  class="emoji-picker"
                />
              </div>
              <button class="btn--text" type="submit"><svg-airplan /></button>
            </div>
            <div class="w-full">
              <div v-for="file in files" :key="file.lastModified" class="file-chip mr-2">
                {{ file.name.length > 24 ? file.name.slice(0, 24) + '...' : file.name }}
                <button class="btn--text" @click="removeFile(file)">✕</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="showJoinChatButton" class="chat__join-btn-wrapper">
        <button @click="joinChat" class="chat__join-btn btn--blue">
          {{ $t('join_chat') }}
        </button>
      </div>
    </div>

    <ChatSettingsModal
      v-if="isSettingsModalVisible"
      :item="chat"
      @close="isSettingsModalVisible = false"
      @updated="getCurrentChat"
    />
  </div>
</template>

<script>
import { firestore } from '../firestore.js';
import firebase from 'firebase/app';
import Message from '@/components/Message';
import Observer from '@/components/Observer';
import Avatar from '@/components//UI/Avatar';
import UserService from '@/api/UserService';
import ChatsService from '@/api/ChatsService';
import { VEmojiPicker } from 'v-emoji-picker';
import axios from 'axios';
import API_URL from '../../config/apiUrl.js';
import { EChatAccessType } from '../enums/campus-chat-access-type.enum';
import { EChatType } from '../enums/chat-type.enum';
import { EChatUserRoleType } from '../enums/chat-user-role-type.enum';
import ChatSettingsModal from '../components/ChatSettingsModal/ChatSettingsModal.vue';
import ENV from '../../config/env.js';
import { EChatVisibilityType } from '../enums/campus-chat-visibility-type.enum';
import { EMessageContentType } from '../enums/message-content-type.enum';

export default {
  components: {
    Message,
    VEmojiPicker,
    Observer,
    ChatSettingsModal,
    Avatar,
  },
  data() {
    return {
      messages: [],
      senderIds: [],
      newMessage: '',
      currentUser: {},
      chatMembers: [],
      chat: {},
      isEmojipickerActive: false,
      files: [],
      imageUrl: '',
      isSettingsModalVisible: false,
      ENV,
      chatId: null,
      aspectRatio: {
        width: 1,
        height: 1,
      },
      isMobile: false,
    };
  },

  created() {
    this.setChatIdFromUrl();
    this.getCurrentUser();
    this.getCurrentChat();
  },
  mounted() {
    if (window.innerWidth < 640) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 640) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  },

  watch: {
    senderIds(newValue, oldValue) {
      this.getChatMembers();
    },
    messages(newValue, oldValue) {
      //check message type. If image is sent, timeout increased
      //so image size is rendered and scroll is done properly
      if (newValue[newValue.length - 1].type === EMessageContentType.Text) {
        setTimeout(this.scrollChatToTop, 100);
      } else {
        setTimeout(this.scrollChatToTop, 500);
      }
    },
  },

  computed: {
    isChatIdAvailable() {
      return this.chatId === null ? false : true;
    },
    userId() {
      let params = new URL(document.location).searchParams;
      let userId = params.get('userId');

      return userId;
    },
    fullName() {
      return this.currentUser?.firstName + ' ' + this.currentUser?.lastName;
    },
    messageContent() {
      if (this.chat?.entityType === 2) {
        return this.fullName + ': ' + (this.imageUrl || this.newMessage);
      }

      return this.imageUrl ? this.imageUrl : this.newMessage;
    },
    chatName() {
      if (this.chat.conversationWith) {
        const { firstName, lastName } = this.chat.conversationWith;

        return `${firstName} ${lastName}`;
      }

      return this.chat.name;
    },
    initials() {
      const { firstName = '', lastName = '' } = this.chat?.conversationWith || {};

      return firstName.slice(0, 1) + lastName.slice(0, 1);
    },

    avatarImg() {
      return this.chat?.conversationWith?.image;
    },
    showAvatar() {
      if (
        this.chat?.entityType === EChatType.UserPrivate ||
        this.chat?.entityType === EChatType.UserPublic
      ) {
        return true;
      } else {
        return false;
      }
    },

    previousPageUrl() {
      if (document.referrer && document.referrer !== document.location.href) {
        return document.referrer;
      }

      return '/app/chats';
    },
    token() {
      return document.getElementById('app-token')?.value;
    },
    showChatInput() {
      if (this.chat.entityType === EChatType.Campus || this.chat.entityType === EChatType.Room) {
        if (this.chat.myRole === EChatUserRoleType.Moderator) {
          return true;
        } else if (
          this.chat.myRole === EChatUserRoleType.Participant &&
          (this.chat.settings.sendAccess === EChatAccessType.AccesibleForParticipants ||
            this.chat.settings.sendAccess === EChatAccessType.AccesibleForParticipantsSubscribers)
        ) {
          return true;
        }
      } else if (
        this.chat.entityType === EChatType.UserPrivate ||
        this.chat.entityType === EChatType.UserPublic
      ) {
        return true;
      }

      return false;
    },
    showJoinChatButton() {
      if (
        (this.chat.entityType === EChatType.Campus || this.chat.entityType === EChatType.Room) &&
        this.chat.myRole === EChatUserRoleType.Nobody
      ) {
        return true;
      }

      return false;
    },
    showSettingsButton() {
      if (
        (this.chat.entityType === EChatType.Campus || this.chat.entityType === EChatType.Room) &&
        this.chat.myRole === EChatUserRoleType.Moderator
      ) {
        return true;
      }

      return false;
    },
    firestoreCollection() {
      return this.ENV === 'local' ? 'development' : 'production';
    },
    isChatAccess() {
      if (this.chat.myRole === EChatUserRoleType.Moderator) {
        console.log('1');

        return true;
      } else if (
        (this.chat.entityType === EChatType.UserPrivate ||
          this.chat.entityType === EChatType.UserPublic) &&
        this.chat.myRole !== EChatUserRoleType.Nobody
      ) {
        console.log('2');

        return true;
      } else if (
        this.chat.entityType === EChatType.Campus ||
        this.chat.entityType === EChatType.Room
      ) {
        if (this.chat.settings.visible === EChatVisibilityType.Visible) {
          console.log('3');

          return true;
        } else if (
          this.chat.myRole === EChatUserRoleType.Participant &&
          (this.chat.settings.visible === EChatVisibilityType.VisibleForParticipants ||
            this.chat.settings.visible === EChatVisibilityType.VisibleForParticipantsSubscribers)
        ) {
          console.log('4');

          return true;
        } else if (
          this.chat.myRole === EChatUserRoleType.Nobody &&
          this.chat.settings.visible !== EChatVisibilityType.Hidden
        ) {
          console.log('5');

          return true;
        }

        return false;
      }

      return false;
    },
    interlocutorUrl() {
      if (this.chat.entityType === EChatType.Campus) {
        return `/app/campus?id=${this.chat?.entityId}`;
      } else {
        return `/app/profile?id=${this.chat?.conversationWith?.id}`;
      }
    },
    chatMembersCount() {
      return this.chat?.membersCount;
    },
  },

  methods: {
    setChatIdFromUrl() {
      let params = new URL(document.location).searchParams;
      this.chatId = params.get('id');
    },
    scrollChatToTop() {
      this.$refs.dummy.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    },
    async initializeChat() {
      console.log(`chat id is ${this.chatId}`);

      let messagesPath = firestore
        .collection(this.firestoreCollection)
        .doc('communication')
        .collection('chats')
        .doc(this.chatId)
        .collection('messages');

      let messagesDocRef = messagesPath.orderBy('createdAt', 'asc');

      messagesDocRef.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type == 'added') {
            let { doc } = change;
            const { content, createdAt, isRead, senderId, type } = doc.data();

            this.messages.push({
              id: doc.id,
              content,
              createdAt,
              isRead,
              senderId,
              type,
            });

            // this.lastMessage = doc;
            !this.senderIds.includes(senderId) && this.senderIds.push(senderId);
          }
        });
      });

      // let messagesDocRef = messagesPath.orderBy('createdAt', 'desc').limit(50);

      // messagesDocRef.get().then((querySnapshot) => {
      //   querySnapshot.forEach((doc) => {
      //     const { content, createdAt, isRead, senderId, type } = doc.data();

      //     this.messages.unshift({
      //       id: doc.id,
      //       content,
      //       createdAt,
      //       isRead,
      //       senderId,
      //       type,
      //     });

      //     this.lastMessage = doc;
      //     !this.senderIds.includes(senderId) && this.senderIds.push(senderId);
      //   });
      // });

      // this.listenForNewMessages();

      await ChatsService.readChat(this.chatId);
    },

    // listenForNewMessages() {
    //   let messagesPath = firestore
    //     .collection(this.firestoreCollection)
    //     .doc('communication')
    //     .collection('chats')
    //     .doc(this.chatId)
    //     .collection('messages');

    //   let messagesDocRef = messagesPath.orderBy('createdAt', 'desc').limit(10);
    //   messagesDocRef.onSnapshot((snapshot) => {
    //     snapshot.docChanges().forEach((change) => {
    //       if (change.type == 'added') {
    //         let { doc } = change;
    //         const { content, createdAt, isRead, senderId, type } = doc.data();

    //         this.messages.push({
    //           id: doc.id,
    //           content,
    //           createdAt,
    //           isRead,
    //           senderId,
    //           type,
    //         });

    //         !this.senderIds.includes(senderId) && this.senderIds.push(senderId);
    //       }
    //     });
    //   });
    // },

    // async getOldMessages() {
    //   let order = firestore
    //     .collection(this.firestoreCollection)
    //     .doc('communication')
    //     .collection('chats')
    //     .doc(this.chatId)
    //     .collection('messages')
    //     .orderBy('createdAt', 'desc')
    //     .startAfter(this.lastMessage)
    //     .limit(50);

    //   order.get().then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       console.log(doc.data().content);

    //       const { content, createdAt, isRead, senderId, type } = doc.data();

    //       this.messages.unshift({
    //         id: doc.id,
    //         content,
    //         createdAt,
    //         isRead,
    //         senderId,
    //         type,
    //       });

    //       this.lastMessage = doc;
    //       !this.senderIds.includes(senderId) && this.senderIds.push(senderId);
    //     });
    //   });
    // },
    async sendMessage() {
      if (this.files.length > 0) {
        await this.sendFile();
      }
      if (this.newMessage || this.imageUrl) {
        let chatReference = firestore
          .collection(this.firestoreCollection)
          .doc('communication')
          .collection('chats')
          .doc(this.chatId);

        chatReference.collection('messages').add({
          aspectRatio: `${this.aspectRatio.width}:${this.aspectRatio.height}`,
          content: this.imageUrl ? this.imageUrl : this.newMessage,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          isRead: false,
          replyDocId: '',
          senderId: this.currentUser?.id,
          type: this.imageUrl ? 1 : 0,
        });

        let chatFields = {
          type: this.chat.entityType,
          id: this.chat.entityId,
        };

        chatReference.set(chatFields);
        this.updateLastMessage();

        this.newMessage = '';
        this.files = [];
        this.imageUrl = '';
      }

      this.isEmojipickerActive = false;
    },
    async updateLastMessage() {
      await ChatsService.updateLastMessage(this.chatId, {
        aspectRatio: '',
        content: this.messageContent,
        replyDocId: '',
        senderId: this.currentUser?.id,
        type: this.imageUrl ? 1 : 0,
      });
    },
    async getCurrentUser() {
      await UserService.getUserProfile().then((response) => {
        this.currentUser = response.data;
      });
    },

    async getCurrentChat() {
      if (this.isChatIdAvailable) {
        await ChatsService.getChat(this.chatId).then((response) => {
          this.chat = response.data.data;
        });
      } else {
        let dataToSend = {
          entityType: 0,
          members: [{ role: 1, user_id: this.userId }],
        };
        await ChatsService.createChat(dataToSend).then((response) => {
          this.chat = response.data;
          let chatId = this.chat?.id;
          this.chatId = chatId.toString();
        });
      }

      await this.initializeChat();

      this.checkChatAccess();
    },

    async getChatMembers() {
      const chatId = this.chatId;
      const users = this.senderIds;
      const { data } = await ChatsService.getChatMembers({ chatId, users });
      this.chatMembers = data;
    },

    async joinChat() {
      await ChatsService.joinChat(this.chatId);
      this.getCurrentChat();
    },

    selectEmoji({ data }) {
      this.newMessage += data;
    },

    invokePicker() {
      this.isEmojipickerActive = !this.isEmojipickerActive;
    },

    async setAspectRatio(aspect) {
      this.aspectRatio = aspect;
    },

    async uploadFile(evt) {
      let aspect;
      this.files.push(evt.target.files[0]);

      let reader = new FileReader();
      reader.readAsDataURL(this.files[0]);

      let setAspect = (aspect) => {
        this.setAspectRatio(aspect);
      };

      reader.onload = async function (e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = async function () {
          aspect = {
            height: await this.height,
            width: await this.width,
          };

          setAspect(aspect);

          return aspect;
        };

        return aspect;
      };

      return aspect;
    },

    async sendFile() {
      const formData = new FormData();
      formData.append('file', this.files[0]);
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.token}`,
      };
      const res = await axios.post(`${API_URL}/chat/upload`, formData, {
        headers,
      });
      this.imageUrl = res.data.data;
    },

    removeFile(file) {
      this.files = this.files.filter((el) => el !== file);
    },

    async loadMore(entry) {
      if (this.messages.length) {
        console.log('loadMore', { entry });
        // this.getOldMessages();
      }
    },

    checkChatAccess() {
      if (this.isChatAccess === true) {
        console.log('is access');

        return;
      } else {
        console.log('no access');
        document.location.href = 'https://megacampus.com/';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dummy {
  min-height: 32px;
}

.chat {
  display: flex;
  flex-direction: column;
  background: var(--white);

  &__name-wrapper {
    margin-left: 16px;

    @media (max-width: $sm) {
      margin: 0 auto 0 0;
    }
  }

  &__members-count {
    font-size: var(--text-xs);
    font-weight: 500;
    color: #9fa2b5;
    margin-top: 2px;
  }

  &__name {
    font-size: var(--text-lg);
    font-weight: 500;
    color: #131313;
  }

  &__dots-wrapper {
    margin-left: auto;

    @media (max-width: $sm) {
      margin-left: 20px;
    }
  }

  &__header,
  &__footer {
    display: flex;
    align-items: center;
  }

  &__header-wrapper {
    background: var(--white);
  }

  &__header {
    position: relative;
    padding: 12px 0;
  }

  &__avatar {
    border-radius: 5px;

    @media (max-width: $sm) {
      width: 36px;
      height: 36px;
      margin-right: 20px;
    }
  }

  &__menu {
    &-box {
      position: absolute;
      right: 0;
      top: 70px;
      z-index: 1;
      width: 100%;
    }
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &__body-wrapper {
    background: #f5f6fc;
    overflow: auto;
    height: calc(100vh - 223px);

    @media (max-width: $sm) {
      height: calc(100vh - 172px);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 32px 0;
  }

  &__footer-wrapper {
    background-color: var(--white);
    border-bottom: 1px solid var(--light-gray);

    .container {
      @media (max-width: $sm) {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }

  &__footer {
    flex-wrap: wrap;
    padding: 16px;

    @media (max-width: $sm) {
      padding: 8px 0;
    }

    &-input-box {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
    }

    input[type='file'] {
      display: none;
    }

    input[type='text'] {
      flex: 1;
      border: none;
      padding-left: 12px;
      color: #92a1bb;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: #92a1bb;
      }
    }

    .emoji-picker {
      position: absolute;
      bottom: 40px;
      right: 0;
    }
  }

  &__footer-buttons {
    display: flex;
  }

  &__join-btn-wrapper {
    border-top: 1px solid #e5e5ea;
    padding: 12px 0;
  }

  &__join-btn {
    width: max-content;
    margin: 0 auto;
    display: block;
  }

  .file-chip {
    margin-top: 8px;
    padding: 4px 4px 4px 12px;
    border-radius: 50px;
    background-color: var(--light-purple);
    display: inline-flex;
    align-items: center;
  }
}
</style>
