import { ApiClient } from './ApiClient.js';
const config = {
  transformResponse: [
    function (data) {
      return JSON.parse(data).data;
    },
  ],
};

export default {
  getUserChatsFeed(page = 1) {
    return ApiClient.get(`/chat/feed?page=${page}`, config);
  },

  getUserFeed() {
    return ApiClient.get('/feed');
  },

  getChatMembers({ chatId, users }) {
    return ApiClient.post(`chat/${chatId}/members`, { users }, config);
  },

  updateLastMessage(chatId, message) {
    return ApiClient.post(`/chat/${chatId}`, { message: message });
  },

  createChat(data) {
    return ApiClient.post(`/chat`, data, config);
  },

  getChat(chatId) {
    return ApiClient.get(`/chat/${chatId}`);
  },

  joinChat(chatId) {
    return ApiClient.get(`/chat/${chatId}/join`);
  },

  readChat(chatId) {
    return ApiClient.get(`/chat/${chatId}/read`);
  },

  updateChatSettings(id, data) {
    return ApiClient.post(`/chat/${id}`, data);
  },
};
