import { EChatVisibilityType } from '../../enums/campus-chat-visibility-type.enum';
import { EChatAccessType } from '../../enums/campus-chat-access-type.enum';

export const chatVisibilityConfig = [
  { value: EChatVisibilityType.Visible, label: 'for_all' },
  {
    value: EChatVisibilityType.VisibleForParticipantsSubscribers,
    label: 'for_participants',
  },
  { value: EChatVisibilityType.VisibleForParticipants, label: 'for_participants_and_subscribers' },
  { value: EChatVisibilityType.Hidden, label: 'for_none' },
];

export const chatAccessConfig = [
  {
    value: EChatAccessType.AccesibleForParticipantsSubscribers,
    label: 'for_participants_and_subscribers',
  },
  {
    value: EChatAccessType.AccesibleForParticipants,
    label: 'for_participants',
  },
  { value: EChatAccessType.Blocked, label: 'for_none' },
];
