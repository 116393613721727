<template>
  <div
    class="message"
    :class="{
      'is-sender': isMessageMine,
      'is-same-author': isSameAuthor,
    }"
  >
    <a :href="`/app/profile?id=${authorId}`">
      <Avatar
        v-if="!isMessageMine && !isSameAuthor"
        class="message__avatar"
        :initials="initials"
        :avatar="avatarImg"
      />
    </a>

    <div class="message__box">
      <p class="message__author" v-if="!isMessageMine">{{ fullName }}</p>

      <img v-if="message.type === 1" :src="$host + message.content" class="message__image" />

      <p v-else class="message__content" v-html="processedContent"></p>
      <p class="message__date">{{ messageDate }}</p>
    </div>
  </div>
</template>

<script>
import Avatar from './UI/Avatar';
export default {
  components: { Avatar },
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    prevMessage: {
      type: Object,
      default: () => {},
    },
    chatMembers: {
      type: Array,
      default: () => [{}],
    },
    isMessageMine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    author() {
      const { senderId } = this.message;
      const author = this.chatMembers.find(({ id }) => id === senderId);

      return author;
    },
    authorId() {
      return this.author?.id;
    },

    isSameAuthor() {
      const { senderId } = this.message;
      const { senderId: prevSenderId } = this.prevMessage || {};

      return senderId === prevSenderId;
    },

    fullName() {
      return (this.author || {}).firstName + ' ' + this.author?.lastName;
    },

    initials() {
      const { firstName = '', lastName = '' } = this.author || {};

      return firstName.slice(0, 1) + lastName.slice(0, 1);
    },

    avatarImg() {
      return this.author?.image;
    },

    messageDate() {
      const OPTIONS = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

      let locale = this.$i18n.locale === 'ru' ? 'ru-RU' : 'en-GB';

      return this.message?.createdAt.toDate().toLocaleTimeString(locale, OPTIONS);
    },

    processedContent() {
      var urlRegex = /(https?:\/\/[^\s]+)/g;

      return this.message?.content.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      });
    },
  },
};
</script>

<style lang="scss">
.message {
  max-width: 60%;
  align-self: flex-start;
  display: flex;
  margin-top: 30px;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  &.is-sender {
    align-self: flex-end;
    flex-direction: row-reverse;
  }

  &.is-same-author {
    margin-top: 16px;
  }

  &__avatar {
    position: absolute;
    bottom: 0;

    &.avatar {
      width: 30px !important;
      height: 30px !important;
      border-radius: 50% !important;
    }
  }

  &__box {
    border-radius: 16px 16px 16px 0;
    padding: 16px;
    transform: translateX(40px);
    flex: 1;
    background: #e2e5ff;
    color: #25252b;
    position: relative;

    @media (max-width: $sm) {
      padding: 12px;
    }

    .is-sender & {
      background: #a2a1e8;
      color: #1a1a1d;
      border-radius: 16px 16px 0 16px;
      transform: translateX(-30px);
    }
  }

  &__author {
    color: #25252b;
    font-weight: 600;
    margin-bottom: 8px;

    @media (max-width: $sm) {
      font-size: var(--text-xs);
      margin-bottom: 4px;
    }
  }

  &__content {
    font-weight: 500;
    line-height: 1.6;
    font-size: var(--text-sm);

    @media (max-width: $sm) {
      font-size: 10px;
    }

    a {
      color: var(--blue);

      .is-sender & {
        color: var(--white);
      }
    }
  }

  &__image {
    max-width: 100%;
  }

  &__date {
    font-size: 10px;
    color: #616161;
    margin-top: 4px;
    text-align: right;

    .is-sender & {
      color: var(--white);
    }

    @media (max-width: $sm) {
      font-size: 10px;
    }
  }
}
</style>
